import React, { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAlphaUp, faSortNumericUp, faSortAlphaDown, faSortNumericDown, faSortAlt, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import TableBody from './TableBody';
import TableRow from './TableRow';
import Loading from './Loading';

enum LineType {
	HEADER,
	DATA,
}

type DataLine<T> = T & { __tableLineType: LineType.DATA };
type HeaderLine = { header: string; __tableLineType: LineType.HEADER };
type Line<T> = DataLine<T> | HeaderLine;

interface SubColumn<K> {
	label: string;
	selectFn: (k: K) => string | number | React.ReactElement;
	align?: 'LEFT' | 'CENTER' | 'RIGHT';
	numeric?: boolean;
	hideLabel?: boolean;
	hideColumn?: boolean;
	noTruncate?: boolean;
	classNameTh?: string;
}

interface ColumnBasic<T> {
	label: string;
	selectFn: (t: T, i: number) => string | number | React.ReactElement;
	align?: 'LEFT' | 'CENTER' | 'RIGHT';
	numeric?: boolean;
	hideLabel?: boolean;
	hideColumn?: boolean;
	noTruncate?: boolean;
	classNameTh?: string;
	className?: (t: T, i: number) => string;
	dynamicClassNameTh?: (t: T, i: number) => string;
}

interface ColumnSort<T> extends ColumnBasic<T> {
	sortFn: (a: T, b: T) => number;
}

interface ColumnAction<T> extends ColumnBasic<T> {
	icon: IconDefinition;
	actionFn: (...args: unknown[]) => unknown;
}

export type Column<T> = ColumnBasic<T> | ColumnSort<T> | ColumnAction<T>;

interface Props<T, K> {
	data: T[];
	loading?: boolean;
	noDataFoundText?: string;

	sublineData?: K[][];
	subColumns?: SubColumn<K>[];
	disableOverflowAuto?: boolean;
	columns: Column<T>[];
	keySelector: (t: T, i: number) => string;
	groupSelector?: (t: T) => string;
	groupHeaderClassName?: string;

	onRowClick?: (t: T) => unknown;

	rowIsHighlighted?: (t: T) => boolean;

	rowIsInvalid?: (t: T) => boolean;
	subRowIsInvalid?: (k: K) => boolean;

	rowIsDisabled?: (t: T) => boolean | undefined;
	subRowIsDisabled?: (k: K) => boolean | undefined;

	className?: string;
	style?: CSSProperties;
}

const Table = <T, K>({
	data,
	noDataFoundText,
	columns,
	keySelector,
	groupSelector,
	groupHeaderClassName,
	sublineData,
	subColumns,
	disableOverflowAuto,
	onRowClick,
	rowIsHighlighted,
	rowIsInvalid,
	subRowIsInvalid,
	rowIsDisabled,
	subRowIsDisabled,
	loading,
	className,
	style,
}: React.PropsWithChildren<Props<T, K>>): React.ReactElement => {
	const { t } = useTranslation();

	const [sortOption, setSortOption] = React.useState<{
		label: string;
		func?: (a: T, b: T) => number;
		modifier: number;
	}>({ label: '___NONE___', func: (a, b) => 0, modifier: 1 });

	const sorted = React.useMemo<Line<T>[]>(() => {
		const withType = data.map<DataLine<T>>(entry => ({
			...entry,
			__tableLineType: LineType.DATA,
		}));
		if (typeof groupSelector === 'undefined') {
			return withType.slice().sort((a, b) => (typeof sortOption.func !== 'undefined' ? sortOption.func(a, b) * sortOption.modifier : 0));
		}

		const grouped = withType.reduce(
			(map, entry) => {
				const group = groupSelector(entry);
				if (!(group in map)) {
					map[group] = [];
				}
				map[group].push(entry);
				return map;
			},
			{} as Record<string, DataLine<T>[]>,
		);
		const groupNames = Object.keys(grouped).sort();

		const result: any[] = [];
		for (const group of groupNames) {
			const header: HeaderLine = {
				__tableLineType: LineType.HEADER,
				header: group,
			};
			result.push(header);

			const sortedGroup = grouped[group].slice().sort((a, b) => (typeof sortOption.func !== 'undefined' ? sortOption.func(a, b) * sortOption.modifier : 0));
			for (const entry of sortedGroup) {
				result.push(entry);
			}
		}
		return result;
	}, [data, groupSelector, sortOption]);

	const highlighted = React.useMemo(() => data.filter(d => rowIsHighlighted?.(d) ?? false).map((d, i) => keySelector(d, i)), [data, keySelector, rowIsHighlighted]);

	return (
		<div className={classnames('text-blue relative text-xs', { 'overflow-auto': !disableOverflowAuto }, className)} style={style}>
			<table className="whitespace-no-wrap relative w-full table-auto border-collapse">
				<thead>
					<tr className="text-left">
						{columns
							.filter(c => c.hideColumn !== true)
							.map(
								c =>
									!c.hideLabel && (
										<th
											key={c.label}
											onClick={() => {
												if ('sortFn' in c) {
													if (sortOption.label === c.label) {
														setSortOption({
															label: c.label,
															func: c.sortFn,
															modifier: sortOption.modifier * -1,
														});
													} else {
														setSortOption({
															label: c.label,
															func: c.sortFn,
															modifier: 1,
														});
													}
												}

												if ('actionFn' in c) {
													c.actionFn();
												}
											}}
											className={classnames('sticky top-0 z-10 select-none whitespace-nowrap bg-white py-3 px-2 text-xs font-normal uppercase', c.classNameTh, {
												'cursor-pointer': 'sortFn' in c || 'actionFn' in c,
												'font-bold': sortOption.label === c.label,
												'text-left': c.align === 'LEFT',
												'text-center': c.align === 'CENTER',
												'text-right': c.align === 'RIGHT',
											})}
										>
											{'actionFn' in c ? (
												<>
													<FontAwesomeIcon icon={c.icon} size="lg" />
													&nbsp;{t(c.label)}
												</>
											) : (
												<>
													{t(c.label).includes('~')
														? t(c.label)
															.split('~')
															.map((l, i) => (
																<React.Fragment key={i}>
																	{l} {i === 0 ? <br /> : null}
																</React.Fragment>
															))
														: t(c.label)}
													&nbsp;
													{'sortFn' in c && sortOption.label !== c.label && <FontAwesomeIcon icon={faSortAlt} />}
													{sortOption.label === c.label && (
														<>
															{sortOption.modifier === 1 ? (
																c.numeric ? (
																	<FontAwesomeIcon icon={faSortNumericDown} />
																) : (
																	<FontAwesomeIcon icon={faSortAlphaDown} />
																)
															) : c.numeric ? (
																<FontAwesomeIcon icon={faSortNumericUp} />
															) : (
																<FontAwesomeIcon icon={faSortAlphaUp} />
															)}
														</>
													)}
												</>
											)}
										</th>
									),
							)}
					</tr>
				</thead>

				<TableBody>
					{sorted.map((d, i) =>
						d.__tableLineType === LineType.HEADER ? (
							<TableRow key={d.header}>
								<td className={classnames('font-semibold', { 'pt-4': i > 0 }, groupHeaderClassName)} colSpan={columns.length}>
									{d.header}
								</td>
							</TableRow>
						) : (
							<React.Fragment key={keySelector(d, i)}>
								<TableRow
									key={keySelector(d, i)}
									extraProps={{
										onClick: () => (rowIsInvalid?.(d) ? null : onRowClick?.(d)),
									}}
									classNames={classnames({
										'cursor-pointer': onRowClick,
										'bg-blue-xlight': i % 2 === 0 && !rowIsDisabled?.(d) && !highlighted.includes(keySelector(d, i)),
										'bg-white': i % 2 !== 0,
										'even:bg-cyan-calm odd:bg-cyan': highlighted.includes(keySelector(d, i)),
										'diagonal-bg-danger cursor-not-allowed': rowIsInvalid?.(d) ?? false,
										'bg-red bg-opacity-25': rowIsDisabled?.(d),
									})}
								>
									{columns
										.filter(c => c.hideColumn !== true)
										.map(c => (
											<td
												key={c.label}
												className={classnames('px-2 py-3', {
													'text-left': c.align === 'LEFT',
													'text-center': c.align === 'CENTER',
													'text-right': c.align === 'RIGHT',
													truncate: !c.noTruncate,
												}, c.dynamicClassNameTh?.(d, i) ?? '',
												c.className?.(d, i) ?? '')}
											>
												{c.selectFn(d, i)}
											</td>
										))}
								</TableRow>

								{sublineData &&
									sublineData[i].map(s => (
										<tr
											key={keySelector(d, i)}
											className={classnames({
												'bg-blue-xlight': i % 2 === 0,
												'bg-white': i % 2 !== 0,
												'diagonal-bg-danger cursor-not-allowed': subRowIsInvalid?.(s) ?? false,
												'bg-red bg-opacity-25': subRowIsDisabled?.(s),
											})}
										>
											{subColumns &&
												subColumns
													.filter(c => c.hideColumn !== true)
													.map((c, i) => (
														<td
															key={c.label + i + '-sub'}
															className={classnames('px-2 py-3', {
																'text-left': c.align === 'LEFT',
																'text-center': c.align === 'CENTER',
																'text-right': c.align === 'RIGHT',
																truncate: !c.noTruncate,
															})}
														>
															{c.selectFn(s)}
														</td>
													))}
										</tr>
									))}
							</React.Fragment>
						),
					)}
				</TableBody>
			</table>
			{loading && (
				<div className="relative h-40">
					<Loading />
				</div>
			)}
			{!loading && noDataFoundText && sorted.length === 0 && <p className="text-blue text-center text-2xl">{t(noDataFoundText)}</p>}
		</div>
	);
};

export default Table;
